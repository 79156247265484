<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import CreateVideo from '@components/video/createvideo'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    CreateVideo,
  },
  data() {
    return {
      category_code: 'M002',
      route_back: `/category/workout/video/${this.$route.params.id}`,

      form: {
        name: '',
        description: '',
      },
    }
  },
  mounted() {},
  methods: {},
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Create Video Workout</h4>
      </div>
      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <CreateVideo
                :category_code="this.category_code"
                :route_back="this.route_back"
                :sub_id="this.$route.params.id"
              ></CreateVideo>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
